import React from 'react';
import { Placement, PositioningStrategy } from '@popperjs/core';
import clsx from 'clsx';
import { usePopper } from 'react-popper';
import { CSSTransition } from 'react-transition-group';
import { useCSSPrefix, useForwardedRef } from '../../hooks';
import { Portal } from '../Portal';
import './BasePopper.scss';

export interface Delay {
  showDelay?: number;
  hideDelay?: number;
}
export interface BasePopperProps extends React.ComponentPropsWithoutRef<'div'> {
  /**
   * Optionally display an arrow pointing towards the reference element
   */
  arrow?: Boolean;
  /**
   * Specify children to render inside the popper window
   */
  children?: React.ReactNode;
  /**
   * Specify popper window placement
   * @default 'top'
   */
  placement?: Placement;
  /**
   * Specify the element to which the popper window will be attached
   */
  referenceElement: HTMLElement | null;
  /**
   * Pass in the state that controls whether the popper window is visible
   */
  show?: boolean;
  /**
   * Pass in the state setter function
   */
  setShow?: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Specify the element that will trigger the popper window to open
   */
  showOnElement?: HTMLElement | null;
  /**
   * Optionally, specify the event that should trigger the popper window to open.
   * The component will be responsible for opening AND closing the popper window when 1 or more of these events are specified.
   */
  showOnElementEvents?: Array<'hover' | 'focus' | 'click'>;
  /**
   * Optionally, specify a delay between the user interacting with the element and the popper window opening / closing
   * @param showDelay specify a number value (ms) to delay showing the tooltip
   * @param hideDelay specify a number value (ms) to delay hiding the tooltip
   */
  showOnElementDelay?: Delay;
  /**
   * Optionally, specify the popper window's css position
   * @default 'absolute'
   */
  strategy?: PositioningStrategy;
  /**
   * Specify whether the popper window should be the same width as the reference element
   */
  sameWidthAsReferenceElement?: boolean;
  /**
   * Specify whether to force the popper to re-render. This is only needed if the reference element has changed
   * and we want to update the position of the popper window.
   *
   * NOTE: Any value supplied to this prop it will force it to update.
   * If this functionality is not desired, leave this option `undefined`.
   */
  shouldUpdate?: boolean;
  /**
   * Optionally, specify a callback to be fired after the popper window has completed its exit animation.
   */
  afterClose?: () => void;
}

const POPPER_Z_INDEX = 1250;

// Make popper window the same width as reference element
// Refer to: https://popper.js.org/docs/v2/modifiers/community-modifiers/
const sameWidth = {
  name: 'sameWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }: any) => {
    // eslint-disable-next-line no-param-reassign
    state.styles.popper.width = `${state.rects.reference.width}px`;
  },
  effect: ({ state }: any) => {
    // eslint-disable-next-line no-param-reassign
    state.elements.popper.style.width = `${state.elements.reference.offsetWidth}px`;
  },
};

export function BasePopper({
  arrow = false,
  children,
  placement = 'top',
  referenceElement,
  show: showProp,
  setShow: setShowProp,
  showOnElement,
  showOnElementDelay,
  showOnElementEvents,
  strategy = 'absolute',
  sameWidthAsReferenceElement = false,
  className,
  style,
  shouldUpdate,
  afterClose,
  ...rest
}: BasePopperProps) {
  const [popEl, setPopEl] = React.useState<HTMLDivElement | null>(null);
  const [popArrowEl, setPopArrowEl] = React.useState(null);
  const { showDelay = 0, hideDelay = 0 } = showOnElementDelay ?? ({} as Delay);
  const pop = usePopper(referenceElement, popEl, {
    placement,
    modifiers: [
      {
        name: 'arrow',
        options: {
          element: popArrowEl,
          padding: 4,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, arrow ? 10 : 4],
        },
      },
      ...(sameWidthAsReferenceElement ? [sameWidth as any] : []),
    ],
    strategy,
  });

  // removes CSSTransition findDOMNode error in StrictMode
  const nodeRef = React.useRef(null);
  const timeoutRef = React.useRef<number>();
  const [cssPrefix] = useCSSPrefix();
  const popperRef = React.useRef<HTMLDivElement>(null);
  // Cleanup the timeout
  React.useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  React.useEffect(() => {
    if (typeof shouldUpdate === 'undefined') return;
    pop.update?.();
  }, [shouldUpdate]);

  const onOpenCallback = React.useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setShowProp?.(true);
    }, showDelay);
  }, []);

  const onCloseCallback = React.useCallback(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = window.setTimeout(() => {
      setShowProp?.(false);
    }, hideDelay);
  }, []);

  // Separate callbacks for clicks because we want to prevent the default click action and we need to toggle
  const onClickToggleCallback = React.useCallback((e: MouseEvent) => {
    if (e.defaultPrevented) return;
    e.preventDefault();

    clearTimeout(timeoutRef.current);
    setShowProp?.((val) => !val);
  }, []);

  React.useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const outsideClicked = !referenceElement?.contains(e.target as Node);
      const refElemClicked = e.target === referenceElement;
      const popperClicked = popperRef.current?.contains(e.target as Node);

      if (
        (!popperClicked && outsideClicked) ||
        (refElemClicked && !popperClicked && outsideClicked)
      ) {
        setShowProp?.(false);
      }
    };
    if (showProp) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showProp]);

  React.useEffect(() => {
    if (showOnElement && showOnElementEvents) {
      const eventsAndCallbacks: {
        event: string;
        callback: (e?: any) => void;
      }[] = [];

      for (const e of showOnElementEvents) {
        switch (e) {
          case 'hover':
            eventsAndCallbacks.push({
              event: 'mouseenter',
              callback: onOpenCallback,
            });
            eventsAndCallbacks.push({
              event: 'mouseleave',
              callback: onCloseCallback,
            });
            break;
          case 'focus':
            eventsAndCallbacks.push({
              event: 'focus',
              callback: onOpenCallback,
            });
            eventsAndCallbacks.push({
              event: 'blur',
              callback: onCloseCallback,
            });
            break;
          case 'click':
            eventsAndCallbacks.push({
              event: 'click',
              callback: onClickToggleCallback,
            });
            break;
          default:
            break;
        }
      }

      for (const { event, callback } of eventsAndCallbacks) {
        showOnElement.addEventListener(event, callback);
      }

      return () => {
        for (const { event, callback } of eventsAndCallbacks) {
          showOnElement.removeEventListener(event, callback);
        }
      };
    }
  }, [showOnElement, showOnElementEvents, onOpenCallback, onCloseCallback]);

  React.useEffect(() => {
    if (!showProp) {
      return undefined;
    }
    /**
     * @param {KeyboardEvent} nativeEvent
     */
    const handleKeyDown = (nativeEvent: KeyboardEvent) => {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
        setShowProp?.(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [setShowProp, showProp]);

  return (
    <Portal>
      <CSSTransition
        in={showProp}
        timeout={300}
        classNames={`${cssPrefix}-base-popper-fade`}
        onExited={afterClose}
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div
          {...rest}
          className={`${cssPrefix}-base-popper-wrap`}
          style={{ ...pop.styles.popper, zIndex: POPPER_Z_INDEX, ...style }}
          ref={useForwardedRef(nodeRef, setPopEl)}
          {...pop.attributes.popper}
        >
          <div
            ref={popperRef}
            id="base-popper"
            className={clsx([`${cssPrefix}-base-popper`, className])}
          >
            {children}
            {arrow && (
              <div
                data-popper-arrow // https://popper.js.org/docs/v2/tutorial/#arrow
                style={pop.styles.arrow}
                // popper needs to pass the setter as a ref
                ref={setPopArrowEl as React.LegacyRef<HTMLDivElement>}
                className={`${cssPrefix}-popper-arrow`}
              />
            )}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
}

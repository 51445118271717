/**
 * Credit to MUI for this component: https://github.com/mui/material-ui/blob/master/packages/mui-material/src/Collapse/Collapse.js
 */

import React, { forwardRef } from 'react';
import clsx from 'clsx';
import Transition, {
  TransitionProps as _TransitionProps,
  TransitionActions,
} from 'react-transition-group/Transition';
import { useForwardedRef, useCSSPrefix } from '../../hooks';
import './Collapse.scss';

function getAutoHeightDuration(height?: number) {
  if (!height) {
    return 0;
  }

  const constant = height / 36;

  // https://www.wolframalpha.com/input/?i=(4+%2B+15+*+(x+%2F+36+)+**+0.25+%2B+(x+%2F+36)+%2F+5)+*+10
  return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
}

const TRANSITION_TIMING_FUNCTION = 'cubic-bezier(0.4, 0, 0.2, 1)';

type TransitionHandlerKeys =
  | 'onEnter'
  | 'onEntering'
  | 'onEntered'
  | 'onExit'
  | 'onExiting'
  | 'onExited';

type TransitionKeys =
  | 'in'
  | 'mountOnEnter'
  | 'unmountOnExit'
  | 'timeout'
  | 'easing'
  | 'addEndListener'
  | TransitionHandlerKeys;

interface TransitionProps
  extends TransitionActions,
    Partial<Pick<_TransitionProps, TransitionKeys>>,
    React.HTMLAttributes<HTMLElement> {}

export interface CollapseProps extends Omit<TransitionProps, 'timeout'> {
  children?: React.ReactNode;
  /**
   * The height of the container when collapsed.
   * @default '0px'
   */
  collapsedSize?: string | number;
  /**
   * If `true`, the component will transition in.
   */
  in?: boolean;
  /**
   * Optionally, specify props for the innermost wrapping div.
   */
  innerWrapperProps?: React.HTMLAttributes<HTMLDivElement>;
}

export type CollapseTransitionProps = Pick<
  CollapseProps,
  TransitionHandlerKeys | 'unmountOnExit' | 'addEndListener'
>;

export const Collapse = forwardRef<HTMLDivElement, CollapseProps>(
  (
    {
      addEndListener,
      children,
      className,
      collapsedSize: collapsedSizeProp = '0px',
      in: inProp,
      innerWrapperProps,
      onEnter,
      onEntering,
      onEntered,
      onExit,
      onExited,
      onExiting,
      style,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();

    const { className: innerClassName, ...remainingInnerProps } =
      innerWrapperProps || {};

    const timer = React.useRef(0);
    const wrapperRef = React.useRef(null);
    const autoTransitionDuration = React.useRef(0);
    const collapsedSize =
      typeof collapsedSizeProp === 'number'
        ? `${collapsedSizeProp}px`
        : collapsedSizeProp;

    React.useEffect(() => {
      return () => {
        clearTimeout(timer.current);
      };
    }, []);

    const nodeRef = React.useRef(null);
    const handleRef = useForwardedRef(nodeRef, ref);

    const normalizedTransitionCallback =
      (callback: any) => (maybeIsAppearing?: boolean) => {
        if (callback) {
          const node = nodeRef.current;

          // onEnterXxx and onExitXxx callbacks have a different arguments.length value.
          if (maybeIsAppearing === undefined) {
            callback(node);
          } else {
            callback(node, maybeIsAppearing);
          }
        }
      };

    const getWrapperSize = () =>
      // eslint-disable-next-line @typescript-eslint/dot-notation
      wrapperRef.current ? wrapperRef.current['clientHeight'] : 0;

    const handleEnter = normalizedTransitionCallback(
      (node: HTMLElement, isAppearing: boolean) => {
        node.style.height = collapsedSize;

        if (onEnter) {
          onEnter(node, isAppearing);
        }
      }
    );

    const handleEntering = normalizedTransitionCallback(
      (node: HTMLElement, isAppearing: boolean) => {
        const wrapperSize = getWrapperSize();

        const duration = getAutoHeightDuration(wrapperSize);
        node.style.transitionDuration = `${duration}ms`;
        autoTransitionDuration.current = duration;

        node.style.height = `${wrapperSize}px`;
        node.style.transitionTimingFunction = TRANSITION_TIMING_FUNCTION;

        if (onEntering) {
          onEntering(node, isAppearing);
        }
      }
    );

    const handleEntered = normalizedTransitionCallback(
      (node: HTMLElement, isAppearing: boolean) => {
        node.style.height = 'auto';

        if (onEntered) {
          onEntered(node, isAppearing);
        }
      }
    );

    const handleExit = normalizedTransitionCallback((node: HTMLElement) => {
      node.style.height = `${getWrapperSize()}px`;

      if (onExit) {
        onExit(node);
      }
    });

    const handleExiting = normalizedTransitionCallback((node: HTMLElement) => {
      const wrapperSize = getWrapperSize();

      const duration = getAutoHeightDuration(wrapperSize);
      node.style.transitionDuration = `${duration}ms`;
      autoTransitionDuration.current = duration;

      node.style.height = collapsedSize;
      node.style.transitionTimingFunction = TRANSITION_TIMING_FUNCTION;

      if (onExiting) {
        onExiting(node);
      }
    });

    const handleExited = normalizedTransitionCallback(onExited);

    const handleAddEndListener = (next: any) => {
      timer.current = window.setTimeout(
        // eslint-disable-next-line @typescript-eslint/no-implied-eval
        next,
        autoTransitionDuration.current || 0
      );

      if (addEndListener && nodeRef.current) {
        // Old call signature before `react-transition-group` implemented `nodeRef`
        addEndListener(nodeRef.current, next);
      }
    };

    return (
      <Transition
        {...rest}
        in={inProp}
        onEnter={handleEnter}
        onEntering={handleEntering}
        onEntered={handleEntered}
        onExit={handleExit}
        onExiting={handleExiting}
        onExited={handleExited}
        addEndListener={handleAddEndListener}
        nodeRef={nodeRef}
      >
        {(state) => (
          <div
            ref={handleRef}
            style={{ minHeight: collapsedSize, ...style }}
            className={clsx(`${cssPrefix}-collapse-root`, className, {
              entered: state === 'entered',
              hidden: state === 'exited' && !inProp && collapsedSize === '0px',
            })}
          >
            <div className={`${cssPrefix}-collapse-wrapper`} ref={wrapperRef}>
              <div
                {...remainingInnerProps}
                className={clsx(
                  `${cssPrefix}-collapse-wrapper-inner`,
                  innerClassName
                )}
              >
                {children}
              </div>
            </div>
          </div>
        )}
      </Transition>
    );
  }
);

import React, { ReactNode, useContext, useMemo, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { TabLoop } from '../internal/components/TabLoop';
import { Portal } from '../internal/components/Portal';
import { Key, AnimState } from '../internal/enums';
import {
  useCSSPrefix,
  useForwardedRef,
  useScrollManager,
} from '../internal/hooks';
import { CommonProps } from '../internal/interfaces';
import { Sizes } from '../internal/types';
import { ConfigContext } from '../ConfigProvider';
import './Modal.scss';
import { ModalContext, ModalContextValue } from './ModalContext';

export type ModalScrollMode = 'page' | 'modal-body';

export interface ModalProps
  extends React.ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify the content of the modal.
   */
  children?: ReactNode;
  /**
   * Renders a fullscreen modal.
   */
  fullscreen?: boolean;
  /**
   * Controls whether users are allowed to close the modal by clicking the background or pressing escape.
   */
  disableOverlayClick?: boolean;
  /**
   * Allows you to pass props to the overlay div.
   */
  overlayProps?: React.HTMLAttributes<HTMLDivElement> & CommonProps;
  /**
   * Controls whether the modal body scrolls instead of the page when overflowing.
   */
  scrollMode?: ModalScrollMode;
  /**
   * Specify the open / closed state of the modal.
   */
  show: boolean;
  /**
   * Specify the size of the modal.
   */
  size?: Sizes;
  /**
   * A callback fired when the header closeButton or non-static backdrop is clicked, or escape is pressed.
   */
  onClose: () => void;
  /**
   * Callback fired before the Modal transitions in.
   */
  onEnter?: () => void;
  /**
   * Callback fired as the Modal begins to transition in.
   */
  onEntering?: () => void;
  /**
   * Callback fired after the Modal finishes transitioning in.
   */
  onEntered?: () => void;
  /**
   * Callback fired right before the Modal transitions out.
   */
  onExit?: () => void;
  /**
   * Callback fired as the Modal begins to transition out.
   */
  onExiting?: () => void;
  /**
   * Callback fired after the Modal finishes transitioning out.
   */
  onExited?: () => void;
}

export const Modal = ({
  'aria-labelledby': ariaLabeledBy,
  children,
  className,
  fullscreen,
  disableOverlayClick = false,
  overlayProps = {},
  scrollMode: scrollModeProp,
  show,
  size,
  onClose,
  onEnter,
  onEntering,
  onEntered,
  onExit,
  onExiting,
  onExited,
  id: idProp,
  ...otherModalProps
}: ModalProps) => {
  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);
  // removes CSSTransition findDOMNode error in StrictMode
  const nodeRefOverlay = useRef(null);
  const nodeRefDialog = useRef(null);
  const modalDialogRef = useRef(null);
  const timeout = 300;

  // We're passing our animation state to the modal manager so that it will set the appropriate body classes.
  // We're also passing the modal element so that it can set "overflow: hidden" if another modal opens.
  const [modalElement, setModalElement] = useState<HTMLDivElement | null>(null);
  const [animState, setAnimState] = useState(AnimState.Hidden);
  useScrollManager(show, animState, modalElement);

  // Allow setting "scrollMode" globally instead of having to pass it.
  const { modalScrollMode } = useContext(ConfigContext);
  const scrollMode = scrollModeProp || modalScrollMode || 'page';

  function handleCloseOverlay(e: React.MouseEvent<HTMLDivElement>) {
    if (e.defaultPrevented) return;

    // Only close if the click was directly on the modal wrapper, not on the dialog.
    if (
      !disableOverlayClick &&
      (e.target === modalElement || e.target === modalDialogRef.current)
    ) {
      onClose();
    }
  }

  function handleKeyDown(e: React.KeyboardEvent) {
    if (e.defaultPrevented) return;

    if (e.key === Key.Escape && !disableOverlayClick) {
      e.preventDefault();
      onClose();
    }
  }

  const [ariaLabeledByHeader, setAriaLabeledByHeader] = useState('');

  const ctxValue = useMemo<ModalContextValue>(() => {
    return {
      onClose,
      registerAriaLabeledBy: setAriaLabeledByHeader,
      scrollMode,
    };
  }, [onClose, scrollMode]);

  function handleOnEnter() {
    setAnimState(AnimState.Enter);
    onEnter && onEnter();
  }

  function handleOnEntering() {
    setAnimState(AnimState.Entering);
    onEntering && onEntering();
  }

  function handleOnEntered() {
    setAnimState(AnimState.In);
    onEntered && onEntered();
  }

  function handleOnExit() {
    setAnimState(AnimState.Exit);
    onExit && onExit();
  }

  function handleOnExiting() {
    setAnimState(AnimState.Exiting);
    onExiting && onExiting();
  }

  function handleOnExited() {
    setAnimState(AnimState.Hidden);
    onExited && onExited();
  }

  const { className: overlayClassName, ...overlayOtherProps } = overlayProps;

  return (
    <Portal>
      <ModalContext.Provider value={ctxValue}>
        <CSSTransition
          in={show}
          timeout={timeout}
          classNames={`${cssPrefix}-modal-overlay`}
          unmountOnExit
          nodeRef={nodeRefOverlay}
        >
          <div
            className={clsx(`${cssPrefix}-modal-overlay`, overlayClassName)}
            {...overlayOtherProps}
            ref={nodeRefOverlay}
          />
        </CSSTransition>
        <CSSTransition
          in={show}
          timeout={timeout}
          classNames={`${cssPrefix}-modal-bounce`}
          onEnter={handleOnEnter}
          onEntering={handleOnEntering}
          onEntered={handleOnEntered}
          onExit={handleOnExit}
          onExiting={handleOnExiting}
          onExited={handleOnExited}
          unmountOnExit
          nodeRef={nodeRefDialog}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <div
            {...otherModalProps}
            ref={useForwardedRef(nodeRefDialog, setModalElement)}
            id={id}
            className={clsx([
              `${cssPrefix}-modal`,
              className,
              scrollMode === 'modal-body' && `${cssPrefix}-modal-body-scroll`,
              fullscreen && `${cssPrefix}-modal-fullscreen`,
            ])}
            aria-modal="true"
            aria-labelledby={ariaLabeledBy || ariaLabeledByHeader}
            onClick={handleCloseOverlay}
            onKeyDown={handleKeyDown}
            role="dialog"
          >
            <TabLoop innerContainerRef={modalDialogRef} isModalDialog>
              <div
                className={clsx([
                  `${cssPrefix}-modal-dialog`,
                  size === 'sm' && `${cssPrefix}-modal-sm`,
                  size === 'lg' && `${cssPrefix}-modal-lg`,
                ])}
                ref={modalDialogRef}
              >
                <div className={`${cssPrefix}-modal-content`}>{children}</div>
              </div>
            </TabLoop>
          </div>
        </CSSTransition>
      </ModalContext.Provider>
    </Portal>
  );
};

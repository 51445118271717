import React, { ComponentPropsWithoutRef, forwardRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { MoreSizes } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks';
import { IconMap, IconType } from './icon-map';
import './Icon.scss';

export interface IconProps
  extends ComponentPropsWithoutRef<'svg'>,
    CommonProps {
  /**
   * Specify the icon to render from the following list:
   */
  icon: IconType;
  /**
   * By default the size will be inherited from the current font size. Optionally, specify a size from the following list:
   */
  size?: MoreSizes;
}

export const Icon = forwardRef<SVGSVGElement, IconProps>(
  ({ icon, className, size, id: idProp, ...props }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const Comp: React.VFC<React.SVGProps<SVGSVGElement>> = IconMap[icon];
    const id = useId(idProp);

    return (
      <Comp
        {...props}
        ref={ref}
        id={id}
        className={clsx([
          `${cssPrefix}-icon`,
          size && `${cssPrefix}-icon-${size}`,
          className,
        ])}
      />
    );
  }
);

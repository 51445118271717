import { BreakpointConfigProps } from './useBreakpoints';

const sortBreakpointsValues = (breakpoints: BreakpointConfigProps) => {
  const breakpointsAsArray = Object.keys(breakpoints).map((key) => ({
    key,
    val: breakpoints[key],
  }));

  breakpointsAsArray.sort(
    (breakpoint1, breakpoint2) => breakpoint1.val - breakpoint2.val
  );
  return breakpointsAsArray.reduce((acc, obj) => {
    return { ...acc, [obj.key]: obj.val };
  }, {});
};

const OFFSET = 0.02;

export default function createBreakpoints(breakpoints: BreakpointConfigProps) {
  type Key = keyof typeof breakpoints;

  const sortedBreakpoints = sortBreakpointsValues(
    breakpoints
  ) as BreakpointConfigProps;
  const keys = Object.keys(sortedBreakpoints) as Key[];

  function up(key: Key) {
    return `(min-width:${breakpoints[key]}px)`;
  }

  function down(key: Key) {
    return `(max-width:${breakpoints[key] - OFFSET}px)`;
  }

  function between(start: Key, end: Key) {
    return `(min-width:${breakpoints[start]}px) and (max-width:${
      breakpoints[end] - OFFSET
    }px)`;
  }

  function only(key: Key) {
    if (keys.indexOf(key) + 1 < keys.length) {
      return between(key, keys[keys.indexOf(key) + 1] as Key);
    }

    return up(key);
  }

  return {
    keys,
    values: sortedBreakpoints,
    up,
    down,
    between,
    only,
  };
}

import React, { createContext } from 'react';
import { SurfaceElevation } from '../Surface';

export type AccordionItemKeyType = string | string[] | null;
export type AccordionChangeCallbackType = (
  itemKey: AccordionItemKeyType
) => void;

export interface AccordionContextType {
  /**
   * The item key that corresponds to the currently opened accordion item.
   */
  activeItemKey?: AccordionItemKeyType;
  /**
   * The item key to open by default.
   */
  defaultActiveItemKey?: AccordionItemKeyType;
  /**
   * If `variant="surface"` then specify the elevation to use.
   */
  elevation?: SurfaceElevation;
  /**
   * If `true`, allow accordion items to stay open when another item is opened.
   * @default false
   */
  enableMultipleOpen?: boolean;
  /**
   * Specify the position of the icon.
   * @default: 'end'
   */
  iconPosition?: 'start' | 'end';
  /**
   * Callback fired when the active item changes.
   */
  onChange?: AccordionChangeCallbackType;
  /**
   * Specify the variant of accordion to use.
   */
  variant?: 'default' | 'surface';
  /**
   * Internal state setter function. Should not be exposed!
   */
  setActiveItemKey: (
    value: AccordionItemKeyType | undefined
  ) => void | React.Dispatch<
    React.SetStateAction<AccordionItemKeyType | undefined>
  >;
}

export function isAccordionItemOpen(
  itemKey: string,
  activeItemKey?: AccordionItemKeyType
): boolean {
  return Array.isArray(activeItemKey)
    ? activeItemKey.includes(itemKey)
    : activeItemKey === itemKey;
}

export const AccordionContext = createContext<AccordionContextType | null>(
  null
);

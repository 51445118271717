import React from 'react';
import { ConfigContext } from '../ConfigProvider';
import createBreakpoints from './createBreakpoints';

export interface BreakpointConfigProps {
  [key: string]: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

export const DEFAULT_BREAKPOINTS: BreakpointConfigProps = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export default function useBreakpoints() {
  const { breakpointConfig } = React.useContext(ConfigContext) || {};
  return createBreakpoints(breakpointConfig || DEFAULT_BREAKPOINTS);
}

import React, { forwardRef, useContext } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { useCSSPrefix } from '../internal/hooks';
import { Typography, TypographyProps } from '../Typography';
import { ModalContext } from './ModalContext';

export const ModalBody = forwardRef<HTMLDivElement, TypographyProps>(
  ({ children, className, id: idProp, ...otherProps }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const { scrollMode } = useContext(ModalContext) || {};

    return (
      <Typography
        variant="body1"
        as="div"
        className={clsx(`${cssPrefix}-modal-body`, className)}
        ref={ref}
        id={id}
        {...(scrollMode === 'modal-body' && {
          tabIndex: 0,
        })}
        {...otherProps}
      >
        {children}
      </Typography>
    );
  }
);

import { useEffect, useState } from 'react';
import { PubSub } from '../utils';

// We have a couple places we want to use global state, like "locale" or "css prefix".
// This higher order component creates a hook that uses a "global" value.

type SetGlobalValue<T> = (value: T) => void;

export function withGlobalValue<T>(
  initialVal: T
): (localOverrideValue?: T) => [T, SetGlobalValue<T>] {
  // This variable is effectively global because it's outside the hook.
  let globalValue: T = initialVal;

  // To make components rerender when this changes, we'll have the hook subscribe to a pubsub.
  const pubSub = new PubSub<void>();

  function setGlobalValue(newValue: T) {
    globalValue = newValue;
    pubSub.publish();
  }

  // If we're passed an override, return it. Otherwise, return the global value.
  return function useGlobalVar(overrideValue?: T) {
    const [renderCount, setRenderCount] = useState(1);
    const forceUpdate = () => setRenderCount(renderCount + 1);

    // We need to trigger a rerender for the calling component when the value is changed.
    useEffect(() => {
      return pubSub.subscribe(() => {
        forceUpdate();
      });
    }, []);

    return [overrideValue || globalValue, setGlobalValue];
  };
}

/**
 * Returns appropriate status and descriptiveText based on type of text provided
 * @param errorText
 * @param successText
 * @param helperText

 * @returns
 *  - `status` can be 'error', 'success' or undefined
 *  - `descriptiveText` can be either errorText, successText, or helperText
 */

export const useDescriptiveText = (
  errorText?: string,
  successText?: string,
  helperText?: string
) => {
  const descriptiveText = errorText || successText || helperText;
  const status = errorText ? 'error' : successText ? 'success' : undefined;
  return { status, descriptiveText };
};

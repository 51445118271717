import React from 'react';
import clsx from 'clsx';
import { Icon } from 'Icon';
import { useDescriptiveText } from '../../hooks';
import './HelperText.scss';

export interface HelperTextProps extends React.ComponentPropsWithoutRef<'p'> {
  errorText?: string;
  helperText?: string;
  successText?: string;
  align?: 'left' | 'right';
  children?: React.ReactNode;
}

export const HelperText = ({
  errorText,
  successText,
  helperText,
  align,
  children,
  ...props
}: HelperTextProps) => {
  const { status, descriptiveText } = useDescriptiveText(
    errorText,
    successText,
    helperText
  );

  return descriptiveText ? (
    <p
      className={clsx([
        'helper-text',
        status,
        align === 'right' && 'align-right',
      ])}
      {...props}
    >
      {status && (
        <span>
          <Icon
            icon={status === 'error' ? 'error-filled' : 'success-filled'}
            size="xs"
          />
        </span>
      )}
      {descriptiveText}
    </p>
  ) : null;
};

import React, { forwardRef, useContext, useEffect } from 'react';
import { useId } from '@reach/auto-id';
import clsx from 'clsx';
import { Icon } from '../Icon';
import { useCSSPrefix } from '../internal/hooks';
import { ModalContext } from './ModalContext';
import { Typography, TypographyProps } from '../Typography';

export interface ModalHeaderProps extends TypographyProps {
  /**
   * Allows passing props to the close button.
   */
  closeButtonProps?: React.HTMLAttributes<HTMLButtonElement>;
  /**
   * Controls whether the close button is shown.
   */
  showCloseButton?: boolean;
}

export const ModalHeader = forwardRef<HTMLHeadingElement, ModalHeaderProps>(
  (
    {
      id: idProp,
      children,
      className,
      closeButtonProps = {},
      showCloseButton = true,
      ...rest
    }: ModalHeaderProps,
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const ctx = useContext(ModalContext);

    // Autoregister "labeled-by" with the modal context, so that the modal is labeled by our header.
    const id = useId(idProp);
    useEffect(() => {
      !!id && ctx.registerAriaLabeledBy(id);
    }, [idProp]);

    const {
      className: closeButtonClassName,
      onClick: closeButtonOnClick,
      'aria-label': closeButtonAriaLabel,
      ...otherCloseButtonProps
    } = closeButtonProps;

    return (
      <Typography
        variant="h2"
        id={id}
        className={clsx(`${cssPrefix}-modal-header`, className)}
        ref={ref}
        {...rest}
      >
        {showCloseButton && (
          <button
            type="button"
            {...otherCloseButtonProps}
            // Assign default close button props if we weren't passed any.
            aria-label={closeButtonAriaLabel || 'close'}
            className={clsx(
              `${cssPrefix}-modal-close-btn`,
              closeButtonClassName
            )}
            onClick={closeButtonOnClick || ctx.onClose}
          >
            <Icon aria-hidden="true" icon="close" size="xs" />
          </button>
        )}
        {children}
      </Typography>
    );
  }
);

import React, { ReactNode, useContext } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { Sizes, Colors } from '../internal/types';
import { ButtonGroupContext } from '../ButtonGroup';
import { LoadingIndicator } from '../LoadingIndicator';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import './Button.scss';
import type {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';

export type ButtonVariant = 'filled' | 'outlined' | 'destructive' | 'text';

export type ButtonRef = HTMLButtonElement | HTMLAnchorElement;
export interface ButtonProps
  extends React.HTMLAttributes<ButtonRef>,
    CommonProps {
  /**
   * Specify the size of the component, from the following list of sizes:
   *
   * @default 'lg'
   */
  size?: Sizes;
  /**
   * Specify the variant of the component, from the following list of variants:
   *
   * @default 'filled'
   */
  variant?: ButtonVariant;
  /**
   * Optionally specify if the component should take up the full width of it's container
   *
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Specify the content of your Button
   */
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Specify whether the component should be disabled, or not
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * Optionally specify an href for your Button to become an `<a>` element
   */
  href?: string;
  /**
   * Specify the color of your component
   *
   * @default 'primary'
   */
  color?: Colors;
  /**
   * Specify the loading state of your Button
   */
  loading?: boolean;
  /**
   * Specify the content of your component
   */
  children: ReactNode;
}

const defaultElement = 'button';

export const Button: PolymorphicForwardRefExoticComponent<
  ButtonProps,
  typeof defaultElement
> = React.forwardRef(function Button<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    size,
    variant,
    color,
    fullWidth = false,
    className,
    href,
    disabled,
    loading = false,
    children,
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<ButtonProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = href ? 'a' : as || defaultElement;
  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);

  const {
    color: buttonGroupColor,
    size: buttonGroupSize,
    disabled: buttonGroupDisabled,
    variant: buttonGroupVariant,
  } = useContext(ButtonGroupContext) || {};

  const colorToUse = color ?? buttonGroupColor ?? 'primary';
  const sizeToUse = size ?? buttonGroupSize ?? 'lg';
  const disabledToUse = disabled ?? buttonGroupDisabled;
  const variantToUse = variant ?? buttonGroupVariant ?? 'filled';

  const role =
    // if 'button'
    Component === 'button'
      ? 'button'
      : // if 'a' tag or React Router Link
      Component === 'a' || typeof Component === 'object'
      ? 'link'
      : null;

  const isDisabled = disabledToUse || loading;

  return (
    <Component
      {...rest}
      ref={ref}
      id={id}
      className={clsx([
        `${cssPrefix}-button`,
        `button-size-${sizeToUse}`,
        `button-variant-${variantToUse}`,
        `button-color-${colorToUse}`,
        fullWidth && 'fullwidth',
        className,
        isDisabled && 'disabled',
      ])}
      role={role}
      href={(Component === 'a' && href) || undefined}
      disabled={isDisabled}
    >
      {loading && (
        <LoadingIndicator
          variant="inline"
          className="button-loading"
          disabled
          size="sm"
        />
      )}
      {loading && children && <>&nbsp;&nbsp;</>}
      {children}
    </Component>
  );
});

/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { ReactNode, useEffect, useRef } from 'react';
import Bowser from 'bowser';

// From: https://zellwk.com/blog/keyboard-focusable-elements/
function getFocusableElements(container: HTMLElement | null): HTMLElement[] {
  if (!container || !container.querySelectorAll) return [];

  const nodeList = container.querySelectorAll(
    `[tabindex], a, button, input, select, textarea, details`
  );
  const nodeArr = [...nodeList] as HTMLElement[];
  return nodeArr.filter(
    (el) =>
      !(el as any).disabled &&
      el.tabIndex !== -1 &&
      !el.getAttribute('aria-hidden') &&
      !el.dataset.sentinel
  );
}

export interface TabLoopProps {
  /**
   * By default, TabLoop will move the focus to within itself when it first renders.
   * You can disable this behavior by setting `autoFocus` to false.
   */
  autoFocus?: boolean;
  /**
   * By default, TabLoop will autofocus its innerContainer element. However, you can pass a ref
   * to focus a different element instead.
   */
  autoFocusElementRef?: React.MutableRefObject<HTMLElement | null>;
  /**
   * Elements inside the TabLoop. See notes on innerContainerRef.
   */
  children: ReactNode;
  /**
   * Keeps focus within TabLoop. This should only be turned off for specific situations,
   * like if you have a popup that's always in the dom, but is only shown sometimes through CSS.
   * In this case, you only want to enable TabLoop when the popup is visible.
   */
  enabled?: boolean;
  /**
   * The child element to keep focus within. Must be a child of TabLoop in order for it to work correctly.
   * @example
   * <TabLoop innerContainerRef={innerContainerRef}>
   *   <div ref={innerContainerRef}>
   *     <input />
   *     <button />
   *   </div>
   * </TabLoop>
   */
  innerContainerRef: React.MutableRefObject<HTMLElement | null>;
  /**
   * Some browsers like Safari will autofocus the first element inside a modal dialog.
   * This messes with our focus handling, because our first element is a sentinel div.
   * To work around this, we ignore focus events for a short delay when opening.
   */
  isModalDialog?: boolean;
}

/**
 * Keeps focus within this element. This is needed for modals to prevent a user from tabbing
 * to background elements. We're copying the technique used by Material UI with sentinel divs.
 *
 * Expected behavior:
 * - We add sentinel divs before and after `children`.
 * - When we first render, we'll autofocus the inner container, which must be inside TabLoop.
 * - If the user presses Tab or Shift-Tab, they'll hit our "sentinel" divs on either end.
 * - When they focus a sentinel:
 *   - If they focus the start sentinel, we'll focus the last focusable element.
 *   - If they focus the last sentinel, we'll focus the first focusable element.
 */
export const TabLoop = ({
  autoFocus = true,
  autoFocusElementRef,
  children,
  enabled = true,
  innerContainerRef,
  isModalDialog = false,
}: TabLoopProps) => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isSafari = browser.satisfies({
    safari: '>=1',
  });

  // NOTE: Safari autofocuses the first element in a modal dialog. Haven't figured out a way to turn that off,
  // so we need to ignore that first autofocus. We're delaying our own autofocus until after Safari autofocuses.
  const isIgnoringFocus = useRef(true);
  const autofocusTimeout = isModalDialog && isSafari ? 300 : 0;

  useEffect(() => {
    if (enabled) {
      // Wait until after the browser has done its native focusing for modals.
      const timeout = setTimeout(() => {
        // Now that the browser has done any of its own autofocusing, we can enable the sentinel divs.
        isIgnoringFocus.current = false;

        if (autoFocus && innerContainerRef.current) {
          // We need to make the innerContainer focusable before focusing it.
          // eslint-disable-next-line no-param-reassign
          innerContainerRef.current.tabIndex = -1;

          const focusTarget = autoFocusElementRef
            ? autoFocusElementRef.current
            : innerContainerRef.current;
          if (focusTarget) {
            // Using preventScroll because if we scroll to a focused modal,
            // it makes the scroll position start slightly not at the top because of the modal's margin.
            focusTarget.focus({ preventScroll: true });
          }
        }
      }, autofocusTimeout);

      return () => clearTimeout(timeout);
      // eslint-disable-next-line no-else-return
    } else {
      // If we get disabled, we'll need to autofocus again later, so set this back to true.
      isIgnoringFocus.current = true;
    }
  }, [autoFocus, enabled]);

  // If the user hits the first div, then they're moving out of the container.
  // We need to focus the last element to loop around.
  const handleFocusStart = () => {
    if (!enabled || isIgnoringFocus.current) {
      return;
    }

    // User pressed shift-tab to hit the first sentinel, loop focus to last element.
    const elements = getFocusableElements(innerContainerRef.current);

    if (elements.length > 0) {
      elements[elements.length - 1].focus();
    }
  };

  const handleFocusEnd = () => {
    if (!enabled || isIgnoringFocus.current) {
      return;
    }

    // User has tabbed out of container, need to focus first element.
    const elements = getFocusableElements(innerContainerRef.current);

    if (elements.length > 0) {
      elements[0].focus();
    }
  };

  return (
    <>
      <div tabIndex={0} onFocus={handleFocusStart} data-sentinel="start" />
      {children}
      <div tabIndex={0} onFocus={handleFocusEnd} data-sentinel="end" />
    </>
  );
};

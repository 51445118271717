import React, { ComponentPropsWithoutRef, ReactNode } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import './Surface.scss';
import { useCSSPrefix } from '../internal/hooks';
import {
  PolymorphicForwardRefExoticComponent,
  PolymorphicPropsWithoutRef,
  PolymorphicRef,
} from '../internal/types/Polymorphic';

export type SurfaceVariant = 'default' | 'outlined';
export type SurfaceRadii = 'none' | 'sm' | 'md';
export type SurfaceElevation = 0 | 1 | 2 | 4 | 8 | 16;

export interface SurfaceProps
  extends ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify the content of the component.
   */
  children?: ReactNode;
  /**
   * Specify the variant of the component.
   */
  variant?: SurfaceVariant;
  /**
   * Specify the border radius of the component.
   */
  radii?: SurfaceRadii;
  /**
   * Specify the elevation of the component.
   */
  elevation?: SurfaceElevation;
}

const defaultElement = 'div';

export const Surface: PolymorphicForwardRefExoticComponent<
  SurfaceProps,
  typeof defaultElement
> = React.forwardRef(function Surface<
  T extends React.ElementType = typeof defaultElement
>(
  {
    as,
    children,
    variant = 'default',
    radii = 'sm',
    elevation = 0,
    className,
    id: idProp,
    ...rest
  }: PolymorphicPropsWithoutRef<SurfaceProps, T>,
  ref: PolymorphicRef<T>
) {
  const Component: React.ElementType = as || defaultElement;

  const [cssPrefix] = useCSSPrefix();
  const id = useId(idProp);

  return (
    <Component
      {...rest}
      ref={ref}
      id={id}
      className={clsx([
        `${cssPrefix}-surface`,
        `surface-${variant}`,
        radii !== 'none' && `surface-radii-${radii}`,
        `surface-elevation-${elevation}`,
        className,
      ])}
    >
      {children}
    </Component>
  );
});

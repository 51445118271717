import React, { createContext, forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { Sizes, Colors } from '../internal/types';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix } from '../internal/hooks';
import { ButtonVariant, ButtonProps } from '../Button';
import './ButtonGroup.scss';

export interface IButtonGroupContext {
  color: Colors;
  size: Sizes;
  disabled: boolean;
  variant: ButtonVariant;
}

export const ButtonGroupContext = createContext<IButtonGroupContext | null>(
  null
);

export type ButtonGroupOrientation = 'horizontal' | 'vertical';

export interface ButtonGroupProps
  extends Pick<
      ButtonProps,
      'size' | 'variant' | 'color' | 'disabled' | 'fullWidth' | 'children'
    >,
    CommonProps {
  /**
   * Specify the orientation of the Button Group
   */
  orientation?: ButtonGroupOrientation;
}

export const ButtonGroup = forwardRef<HTMLDivElement, ButtonGroupProps>(
  (
    {
      orientation = 'horizontal',
      color = 'primary',
      disabled = false,
      size = 'lg',
      fullWidth = false,
      variant = 'filled',
      className,
      children,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const value = useMemo(
      () => ({
        color,
        size,
        disabled,
        variant,
      }),
      [color, size, disabled, variant]
    );

    return (
      <ButtonGroupContext.Provider value={value}>
        <div
          {...rest}
          ref={ref}
          id={id}
          className={clsx([
            className,
            `${cssPrefix}-buttongroup`,
            orientation,
            fullWidth && 'fullwidth',
          ])}
          role="group"
        >
          {children}
        </div>
      </ButtonGroupContext.Provider>
    );
  }
);

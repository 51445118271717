/**
 * Info on `KeyboardEvent.key` mappings: https://developer.mozilla.org/en-US/docs/Web/API/UI_Events/Keyboard_event_key_values#whitespace_keys
 */
export enum Key {
  ArrowDown = 'ArrowDown',
  ArrowUp = 'ArrowUp',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
  Escape = 'Escape',
  Tab = 'Tab',
  PageUp = 'PageUp',
  PageDown = 'PageDown',
  Home = 'Home',
  End = 'End',
  Space = ' ',
  Backspace = 'Backspace',
  Shift = 'Shift',
  Alt = 'Alt',
  Option = 'Option',
  Meta = 'Meta',
}

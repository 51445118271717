import React from 'react';
import clsx from 'clsx';
import './InputLabel.scss';
import { useCSSPrefix } from '../../hooks/useCSSPrefix';

export interface InputLabelProps
  extends React.LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean;
  required?: boolean;
  hideRequiredStyle?: boolean;
}

export const InputLabel = ({
  disabled = undefined,
  className: classNameProp,
  required,
  hideRequiredStyle,
  ...props
}: InputLabelProps) => {
  const [cssPrefix] = useCSSPrefix();

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      {...props}
      className={clsx([
        `${cssPrefix}-input-label`,
        disabled && `${cssPrefix}-disabled`,
        required && !hideRequiredStyle && `${cssPrefix}-required`,
        classNameProp,
      ])}
    />
  );
};

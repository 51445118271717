import { ReactNode, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';

/**
 * This component is a safeguard in case the consumer is using server side rendering.
 * It checks to make sure the component is mounted before using createPortal
 */

export interface PortalProps {
  /**
   * Elements inside of the Portal.
   */
  children: ReactNode;
}

export const Portal = ({ children }: PortalProps) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => setMounted(false);
  }, []);

  return mounted ? createPortal(children, document.body) : null;
};

import React from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { Sizes } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks';
import { Logo } from '../Logo';
import './LoadingIndicator.scss';

export type LoadingType = 'inline' | 'page';

export interface LoadingProps
  extends React.ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify a variant to render from the following list:
   */
  variant: LoadingType;
  /**
   * Optionally, specify a size from the following list:
   */
  size?: Sizes;
  /**
   * Specify whether the Loading Indicator should have disabled styles, or not
   *
   * @default false
   */
  disabled?: boolean;
}

export const LoadingIndicator = React.forwardRef<HTMLDivElement, LoadingProps>(
  ({ className, variant, size = 'lg', disabled, id: idProp, ...rest }, ref) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const count = variant === 'inline' ? 8 : 64;

    return (
      <div
        {...rest}
        ref={ref}
        id={id}
        className={clsx(
          `${cssPrefix}-loading-indicator`,
          variant === 'inline' && `${cssPrefix}-loading-indicator-inline`,
          className
        )}
        role="status"
        aria-busy
      >
        <div
          className={clsx([
            `${cssPrefix}-loading-circlegraph-${variant}-${size}`,
          ])}
          aria-hidden="true"
        >
          {[...Array(count)].map((_, i) => (
            <div
              key={i}
              className={clsx([
                `${cssPrefix}-loading-dot`,
                `${cssPrefix}-loading-dot-${variant}`,
                `${cssPrefix}-loading-dot-${variant}-i${i}`,
                disabled && 'disabled',
                size,
              ])}
            />
          ))}
        </div>
        {variant === 'page' && (
          <Logo
            data-testid="logo"
            className={clsx(
              `${cssPrefix}-loading-logo`,
              disabled && 'disabled',
              size
            )}
            logo="symbol"
            size={size}
          />
        )}
      </div>
    );
  }
);

import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { MoreColors } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks';
import './Typography.scss';

export const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  subtitle1: 'h5',
  subtitle2: 'h5',
  subtitle3: 'h5',
  overline1: 'h6',
  overline2: 'h6',
  overline3: 'h6',
  caption1: 'div',
  caption2: 'div',
  body1: 'p',
  body2: 'p',
};

export type TypographyVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subtitle1'
  | 'subtitle2'
  | 'subtitle3'
  | 'overline1'
  | 'overline2'
  | 'overline3'
  | 'caption1'
  | 'caption2'
  | 'body1'
  | 'body2';

export interface TypographyProps
  extends React.HTMLAttributes<
    | HTMLAnchorElement
    | HTMLDivElement
    | HTMLHeadingElement
    | HTMLParagraphElement
    | HTMLSpanElement
  > {
  /**
   * Optionally, specify the variant to use from the following list:
   */
  variant?: TypographyVariant;
  /**
   * Optionally, specify the color to use from the following list:
   */
  color?: MoreColors;
  /**
   * Optionally, specify the underlying DOM element to use instead of the the default for the variant.
   *
   * @default {
   * h1: 'h1',
   * h2: 'h2',
   * h3: 'h3',
   * h4: 'h4',
   * subtitle1: 'h5',
   * subtitle2: 'h5',
   * subtitle3: 'h5',
   * overline1: 'h6',
   * overline2: 'h6',
   * overline3: 'h6',
   * caption1: 'div',
   * caption2: 'div',
   *  body1: 'p',
   * body2: 'p',
   * }
   */
  as?: React.ElementType;
  /**
   * Optionally, specify a font weight to use instead of the default for the variant.
   */
  fontWeight?: 'extralight' | 'regular' | 'medium' | 'bold' | 'extrabold';
  /**
   * Specify the alignment of the text.
   */
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  /**
   * The content of the component.
   */
  children: React.ReactNode;
  /**
   * If `true`, adds bottom margin to the text.
   *
   * @default false
   */
  gutterBottom?: boolean;
  /**
   * Specify whether the typography should be disabled, or not
   *
   * @default false
   */
  disabled?: boolean;
}

export const Typography = forwardRef<any, TypographyProps>(
  (
    {
      variant = 'body1',
      color = 'default',
      as,
      gutterBottom = false,
      disabled,
      fontWeight,
      align,
      className,
      id: idProp,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();

    const Component = as || variantMapping[variant];

    const id = useId(idProp);

    return (
      <Component
        {...props}
        ref={ref}
        id={id}
        className={clsx([
          `${cssPrefix}-typography`,
          `${cssPrefix}-typography-variant-${variant}`,
          `${cssPrefix}-typography-color__${!disabled ? color : 'disabled'}`,
          gutterBottom && 'gutter-bottom',
          fontWeight && `weight-${fontWeight}`,
          align && `align-${align}`,
          className,
        ])}
        aria-disabled={disabled}
      />
    );
  }
);

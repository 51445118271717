import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * When one of our library's components is "un-controlled" this hook internally handles it as a "controlled" component.
 * In this hook the incoming `defaultValue` parameter initializes the internal state (line 10). If a consumer is
 * using our component as "un-controlled", then we use the `setValueIfUncontrolled` function to update this internal state.
 *
 * NOTE: When using this hook, please ensure that you are supplying some kind of initial value to the `defaultValue` argument.
 * Otherwise the internal state will change from `undefined` to `some value` and React will throw a warning.
 */

function useControlledState<T>(
  defaultValue: T,
  controlled: T,
  componentName: string
): [T, (value: T) => void | React.Dispatch<React.SetStateAction<T>>] {
  // isControlled is ignored in the hook dependency lists as it should never change.
  const { current: isControlled } = useRef(controlled !== undefined);
  const [valueState, setValue] = useState(defaultValue);
  const value = isControlled ? controlled : valueState;

  useEffect(() => {
    if (isControlled !== (controlled !== undefined)) {
      console.error(
        [
          `CDS: A component is changing the ${
            isControlled ? '' : 'un'
          }controlled state of ${componentName} to be ${
            isControlled ? 'un' : ''
          }controlled.`,
          'Elements should not switch from uncontrolled to controlled (or vice versa).',
          `Decide between using a controlled or uncontrolled ${componentName} ` +
            'element for the lifetime of the component.',
          "The nature of the state is determined during the first render. It's considered controlled if the value is not `undefined`.",
          'More info: https://fb.me/react-controlled-components',
        ].join('\n')
      );
    }
  }, [componentName, controlled]);

  const { current: defaultPropValue } = useRef(defaultValue);

  useEffect(() => {
    if (!isControlled && defaultPropValue !== defaultValue) {
      console.error(
        [
          `CDS: A component is changing the default state of an uncontrolled ${componentName} after being initialized. ` +
            `To suppress this warning opt to use a controlled ${componentName}.`,
        ].join('\n')
      );
    }
  }, [JSON.stringify(defaultValue)]);

  const setValueIfUncontrolled = useCallback((newValue) => {
    if (!isControlled) {
      setValue(newValue);
    }
  }, []);

  return [value, setValueIfUncontrolled];
}

export { useControlledState };

import React, { forwardRef, ReactNode, useEffect, useState } from 'react';
import { useId } from '@reach/auto-id';
import clsx from 'clsx';
import scrollIntoView from 'scroll-into-view-if-needed';
import { Key } from '../internal/enums';
import {
  useForwardedRef,
  useCSSPrefix,
  useDescriptiveText,
} from '../internal/hooks';
import { FormFieldProps } from '../internal/interfaces';
import { Icon } from '../Icon';
import { HelperText } from '../internal/components/HelperText';
import { InputLabel } from '../internal/components/InputLabel';
import { BasePopper } from '../internal/components/BasePopper';
import './Dropdown.scss';

// this component does not extend html props, as many html event handlers and aria attributes will not work expectedly
export interface DropdownProps<T = string> extends FormFieldProps {
  /**
   * The options to choose from.
   * Note: If options is an array of objects rather than strings, must be used in conjunction with getOptionText.
   */
  options: T[];
  /**
   * Controls rendering of options, allows for use cases such as rendering an icon next to option text.
   */
  renderOption?: (option: T) => ReactNode;
  /**
   * Specify a function to extract the option id
   * @default (option: string) => option
   */
  getOptionId?: (option: T) => string;
  /**
   * Specify a function to resolve an option to a string value. Used for filtering and accessibility.
   * @default (option: string) => option
   */
  getOptionText?: (option: T) => string;
  /**
   * Specify the current value (pass state as prop)
   */
  value: T | null | '';
  /**
   * Optionally, specify a function to be called when the input value changes
   */
  onChange: (value: T | null) => void;
}

// For get unique key to list item
const getKey = (index: number, primaryKey?: string): string => {
  if (primaryKey) {
    return primaryKey;
  }

  return index.toString();
};

// eslint-disable-next-line @typescript-eslint/naming-convention
function _Dropdown<T = string>(
  {
    value,
    label,
    id: idProp,
    options,
    onChange: onChangeProp,
    renderOption,
    getOptionId = (option) => option as string,
    getOptionText = (option) => option as string,
    placeholder,
    errorText,
    successText,
    helperText,
    fullWidth,
    disabled,
    readOnly,
    margin = false,
    compact = false,
    required = false,
    hideRequiredStyle = false,
    className,
    style,
    ...rest
  }: DropdownProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const [cssPrefix] = useCSSPrefix();
  const { status } = useDescriptiveText(errorText, successText, helperText);

  const [navigationValue, setNavigationValue] = useState<T | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [typeAheadQuery, setTypeAheadQuery] = useState('');

  const id = useId(idProp);
  const disabledOrReadonly = disabled || readOnly;

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setNavigationValue(null);
    referenceElement?.focus();
  };

  const navigate = (option: T) => {
    setNavigationValue(option);
  };

  const scrollToOption = () => {
    const itemElement = document.getElementsByClassName('highlighted')[0];
    if (itemElement) {
      scrollIntoView(itemElement, {
        scrollMode: 'if-needed',
        block: 'nearest',
        inline: 'nearest',
        boundary: itemElement.parentElement,
      });
    }
  };

  // If duplicate values exist, we throw an internal error.
  const hasDuplicateValue = new Set(options).size !== options.length;
  useEffect(() => {
    // update console warning here
    hasDuplicateValue &&
      console.error(
        'CDS: An instance of Dropdown has duplicate strings in the array supplied to the `options` prop. To use duplicate values, supply an array of objects and specify a unique key.'
      );
  }, [options]);

  useEffect(() => {
    setNavigationValue(null);

    if (!isOpen) return;

    if (value) {
      navigate(value);
    } else {
      navigate(options[0]);
    }
    scrollToOption();
  }, [isOpen]);

  useEffect(() => {
    scrollToOption();
  }, [navigationValue]);

  const selectOption = (option: T) => {
    if (option && option !== value) {
      onChangeProp?.(option);
    }
    close();
  };

  const getCurrentIndex = () => {
    return navigationValue
      ? options.indexOf(options.find((option) => option === navigationValue)!)
      : 0;
  };

  const getNextOption = () => {
    const nextIndex =
      getCurrentIndex() + 1 > options.length - 1
        ? options.length - 1
        : getCurrentIndex() + 1;
    return options[nextIndex];
  };

  const getPreviousOption = () => {
    const prevIndex = getCurrentIndex() - 1 < 0 ? 0 : getCurrentIndex() - 1;
    return options[prevIndex];
  };

  const appendAndSearch = (key: string) => {
    if ([Key.Alt, Key.Option, Key.Shift, Key.Meta].includes(key as Key)) {
      return;
    }

    setTypeAheadQuery((searchText) => searchText + key);
  };

  const handleCommonKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === Key.Enter) {
      if (!isOpen) {
        return open();
      }
    }
    if (e.key === Key.ArrowDown) {
      if (!isOpen) {
        return open();
      }
      return navigate(getNextOption());
    }
    if (e.key === Key.ArrowUp) {
      if (!isOpen) {
        return open();
      }
      return navigate(getPreviousOption());
    }
    if (e.key === Key.Tab) {
      if (isOpen) {
        return close();
      }
    }
    if (
      e.key === Key.Enter ||
      // Allow user to search keywords with spaces
      (e.key === Key.Space && typeAheadQuery === '')
    ) {
      if (isOpen) {
        return navigationValue && selectOption(navigationValue);
      }
    }

    if (isOpen) {
      appendAndSearch(e.key);
    }
  };

  const handleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (readOnly) {
      return;
    }

    // Every key which can open the dropdown should run prevent default first
    if (
      !isOpen &&
      [Key.ArrowDown, Key.ArrowUp, Key.Enter].includes(e.key as Key)
    ) {
      e.preventDefault();
    }

    if (isOpen) {
      e.preventDefault();
    }

    handleCommonKeyDown(e);
  };

  const handleOptionKeyDown = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (!isOpen) {
      return;
    }
    handleCommonKeyDown(e);
  };

  const isOptionSelected = (option: T) => {
    return !!(value && value === option);
  };

  const isOptionHighlighted = (index: string) => {
    return navigationValue
      ? options.indexOf(navigationValue).toString() === index
      : false;
  };

  useEffect(() => {
    if (typeAheadQuery) {
      const targetOption = options.find((option: T) =>
        getOptionText(option).toLowerCase().startsWith(typeAheadQuery)
      );
      if (targetOption) {
        navigate(targetOption);
      }
    }

    const timeout = setTimeout(() => {
      if (typeAheadQuery !== '') {
        setTypeAheadQuery('');
      }
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [typeAheadQuery, options]);

  const renderInputSection = () => {
    return value ? (
      renderOption ? (
        <div className={`${cssPrefix}-dropdown-display`}>
          {' '}
          {renderOption(value)}
        </div>
      ) : (
        <span className={`${cssPrefix}-dropdown-display-text`}>
          {typeof value === 'string' && value.toString()}
        </span>
      )
    ) : (
      <span className={`${cssPrefix}-dropdown-placeholder`} aria-disabled>
        {placeholder}
      </span>
    );
  };

  return (
    <div
      {...rest}
      id={id}
      style={style}
      className={clsx([
        `${cssPrefix}-dropdown-wrapper`,
        margin && 'dropdown-margin',
        fullWidth && 'full-width',
        className,
      ])}
    >
      <InputLabel
        id={`dropdown-label-${id}`}
        required={required}
        hideRequiredStyle={hideRequiredStyle}
        className={clsx([
          `${cssPrefix}-dropdown-label`,
          disabled && 'disabled',
        ])}
      >
        {label}
      </InputLabel>
      <button
        type="button"
        id={`dropdown-button-${id}`}
        ref={useForwardedRef(setReferenceElement, ref)}
        onKeyDown={handleButtonKeyDown}
        className={clsx([
          `${cssPrefix}-dropdown`,
          status,
          readOnly && 'readOnly',
          disabled && 'disabled',
          compact && 'compact',
        ])}
        aria-haspopup="listbox"
        aria-expanded={isOpen}
        aria-labelledby={`dropdown-label-${id} dropdown-button-${id}`}
        aria-disabled={disabled ? 'true' : undefined}
        disabled={disabled}
      >
        {renderInputSection()}
        <div className={`${cssPrefix}-dropdown-spacer`} />
        <div
          className={clsx(
            `${cssPrefix}-dropdown-icon-wrapper`,
            isOpen && 'rotate'
          )}
        >
          <Icon icon="chevron-down" size="xs" />
        </div>
      </button>
      <BasePopper
        sameWidthAsReferenceElement
        show={isOpen}
        setShow={setIsOpen}
        placement="bottom-end"
        referenceElement={referenceElement}
        showOnElement={referenceElement}
        showOnElementEvents={!disabledOrReadonly ? ['click'] : undefined}
      >
        <ul
          role="listbox"
          aria-labelledby={`dropdown-label-${id}`}
          className={`${cssPrefix}-dropdown-list`}
          tabIndex={0}
          aria-activedescendant={
            isOpen ? `${id}-${navigationValue}` : undefined
          }
        >
          {options.length &&
            options.map((option, index) => (
              <li
                key={getKey(index, getOptionId?.(option))}
                className={clsx([
                  `${cssPrefix}-dropdown-item`,
                  isOptionSelected(option) && 'selected',
                  isOptionHighlighted(index.toString()) && 'highlighted',
                ])}
                role="option"
                tabIndex={option === navigationValue ? 0 : -1}
                onKeyDown={handleOptionKeyDown}
                onMouseEnter={() => setNavigationValue(option)}
                aria-selected={isOptionSelected(option) ? 'true' : 'false'}
                onClick={() => selectOption(option)}
              >
                {renderOption ? (
                  renderOption(option)
                ) : (
                  <span className={`${cssPrefix}-dropdown-item-text`}>
                    {option}
                  </span>
                )}
              </li>
            ))}
        </ul>
      </BasePopper>
      <HelperText
        errorText={errorText}
        successText={successText}
        helperText={helperText}
      />
    </div>
  );
}

export const Dropdown = forwardRef(_Dropdown) as <T>(
  p: DropdownProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof _Dropdown>;

import React from 'react';
import { useId } from '@reach/auto-id';
import clsx from 'clsx';
import { InputFieldProps } from '../internal/interfaces';
import { Icon } from '../Icon';
import { HelperText } from '../internal/components/HelperText';
import { InputLabel } from '../internal/components/InputLabel';
import {
  useCSSPrefix,
  useControlledState,
  useDescriptiveText,
} from '../internal/hooks';
import './Checkbox.scss';

export interface CheckboxProps
  extends React.ComponentPropsWithRef<'input'>,
    InputFieldProps {
  /**
   * If uncontrolled, specify the default checked state
   */
  defaultChecked?: boolean;
  /**
   * If controlled, specify the checked state
   */
  checked?: boolean;
  /**
   * Specify the indeterminate state
   */
  indeterminate?: boolean;
  /**
   * If controlled, specify a function to be called when the Checkbox value changes
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  /**
   * Optionally, specify props for the label.
   */
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
}

export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      id: idProp,
      defaultChecked = false,
      checked: checkedProp,
      indeterminate = false,
      onChange,
      labelProps,
      disabled,
      helperText,
      label,
      errorText,
      successText,
      'data-testid': dataTestId,
      className,
      style,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const { status } = useDescriptiveText(errorText, successText, helperText);
    const id = useId(idProp);
    const [checked, setChecked] = useControlledState(
      defaultChecked,
      checkedProp,
      'Checkbox'
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // un-controlled
      if (checkedProp === undefined) {
        setChecked(e.target.checked);
      }
      // controlled
      if (onChange) {
        onChange(e);
      }
    };

    const filled = indeterminate || checked;
    const iconToShow = indeterminate ? 'dash' : 'check';

    const idForLabel = id ? `${id}-label` : undefined;
    const idForInput = id ? `${id}-input` : undefined;
    const dataTestIdForLabel = dataTestId ? `${dataTestId}-label` : undefined;
    const dataTestIdForInput = dataTestId ? `${dataTestId}-input` : undefined;

    return (
      <div
        id={id}
        data-testid={dataTestId}
        style={style}
        className={clsx([`${cssPrefix}-checkbox-wrapper`, className])}
      >
        <InputLabel
          {...labelProps}
          id={idForLabel}
          data-testid={dataTestIdForLabel}
          className={`${cssPrefix}-checkbox-label`}
          disabled={disabled}
        >
          <div
            className={clsx([
              `${cssPrefix}-checkbox`,
              filled && 'filled',
              disabled && 'disabled',
              status,
            ])}
          >
            {filled ? (
              <Icon icon={iconToShow} size="xs" data-testid={iconToShow} />
            ) : null}
            <input
              {...props}
              id={idForInput}
              data-testid={dataTestIdForInput}
              className={`${cssPrefix}-checkbox-input`}
              type="checkbox"
              data-indeterminate={indeterminate}
              checked={checked}
              onChange={handleChange}
              disabled={disabled}
              ref={ref}
              aria-describedby={`${id}-helper-text`}
            />
          </div>
          {label}
        </InputLabel>
        <HelperText
          id={`${id}-helper-text`}
          errorText={errorText}
          helperText={helperText}
          successText={successText}
        />
      </div>
    );
  }
);

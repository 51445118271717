import { useEffect, useState } from 'react';
import { AnimState } from '../enums/AnimState';
import { useCSSPrefix } from './useCSSPrefix';
import { getBodyScrollbarWidth } from '../utils/getBodyScrollbarWidth';

interface Pane {
  animState: AnimState;
  el: HTMLElement | null;
  hasRestoredFocus?: boolean;
  id: number;
  prevFocusedElement?: HTMLElement;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export class _OverlayManager {
  private nextPaneId = 1;
  // When we open a modal / drawer, we'll push the current state here. We're using this for:
  //  - Saving / restoring focus to the element that was focused before the pane opened.
  //  - Setting "overflow: hidden" on the body when a pane is open.
  //  - Setting padding to replace the scrollbar if necessary. This prevents a visual shift.
  private panes: Pane[] = [];
  private isBodyClassAdded = false;

  public createPaneId = (): number => {
    const id = this.nextPaneId;
    this.nextPaneId++;
    return id;
  };

  // This allows us to keep track of which modals are open.
  // When a modal is rendered or its animation state changes, it will call this so that our state is current.
  // We won't have an element until the modal has rendered at least once.
  public onPaneStateChange = (
    id: number,
    animState: AnimState,
    element: HTMLElement | null,
    cssPrefix: string
  ) => {
    if (animState === AnimState.Hidden) {
      const i = this.panes.findIndex((pane) => pane.id === id);
      if (i !== -1) {
        // Modal is now hidden, remove it from our stack of open modals and restore focus.
        // We're using a timeout because react doesn't like it if you call "focus" during a render instead of in an effect.
        const focusEl = this.panes[i].prevFocusedElement;
        this.panes.splice(i, 1);
        setTimeout(() => focusEl?.focus(), 0);
      }
    } else {
      // Modal isn't hidden, add it to our stack
      const pane = this.panes.find((m) => m.id === id);

      if (pane) {
        // Already in stack, update its props.
        pane.animState = animState;
        pane.el = element;
      } else {
        // Hasn't been added yet.
        this.panes.push({
          animState,
          el: element,
          id,
          prevFocusedElement:
            typeof window !== 'undefined'
              ? (document.activeElement as HTMLElement)
              : undefined,
        });
      }
    }

    this.setBodyClasses(cssPrefix);
  };

  private setBodyClasses = (cssPrefix: string) => {
    const isPaneOpen = this.panes.length > 0;
    const bodyClass = `${cssPrefix}-disable-body-scroll`;
    const modalClass = `${cssPrefix}-nested-modal-open`;

    const windowExists =
      typeof window !== 'undefined' && typeof window.document !== 'undefined';

    // Add body padding if the body has a scrollbar to prevent a layout shift.
    if (isPaneOpen && !this.isBodyClassAdded && windowExists) {
      this.isBodyClassAdded = true;

      const { body } = window.document;
      const scrollbarWidth = getBodyScrollbarWidth();
      const bodyStyle = window.getComputedStyle(body);
      const existingPadding = parseInt(bodyStyle.paddingRight, 10) || 0;
      document.body.style.paddingRight = `${
        existingPadding + scrollbarWidth
      } px`;
    } else if (!isPaneOpen && this.isBodyClassAdded) {
      this.isBodyClassAdded = false;
      document.body.style.removeProperty('padding-right');
    }

    windowExists && document.body.classList.toggle(bodyClass, isPaneOpen);

    // Hide overflow on modals that aren't the top one.
    const topModalId = this.getTopPaneId();

    for (const modal of this.panes) {
      modal.el?.classList.toggle(modalClass, modal.id !== topModalId);
    }
  };

  private getTopPaneId(): number | undefined {
    if (this.panes.length === 0) return undefined;

    return this.panes[this.panes.length - 1].id;
  }
}

export const OverlayManager = new _OverlayManager();

export function useScrollManager(
  show: boolean,
  animState: AnimState,
  element: HTMLElement | null,
  manager = OverlayManager,
  preventDisableBodyScroll = false
): {
  paneId: number;
} {
  const [paneId] = useState(() => manager.createPaneId());
  const [cssPrefix] = useCSSPrefix();

  // If the pane is just starting to be shown, the animations won't have started yet,
  // but we need to capture the previously focused element before the TabLoop runs its autofocus.
  if (show && animState === AnimState.Hidden) {
    // eslint-disable-next-line no-param-reassign
    animState = AnimState.Enter;
  }

  if (!preventDisableBodyScroll) {
    // Keep the manager's state current by calling this on every render.
    manager.onPaneStateChange(paneId, animState, element, cssPrefix);
  }

  // Unregister when we unmount
  useEffect(() => {
    return () =>
      manager.onPaneStateChange(paneId, AnimState.Hidden, null, cssPrefix);
  }, []);

  return {
    paneId,
  };
}

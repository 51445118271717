import React, { ComponentPropsWithoutRef } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import './TextField.scss';
import { InputLabel } from '../internal/components/InputLabel';
import { HelperText } from '../internal/components/HelperText';
import {
  useControlledState,
  useCSSPrefix,
  useDescriptiveText,
} from '../internal/hooks';
import { FormFieldProps } from '../internal/interfaces';

export type TextFieldType = HTMLInputElement | HTMLTextAreaElement;

export type TextFieldAlign = 'start' | 'end';

const validateMaxLength = (maxLength: number) => {
  if (maxLength <= 0) {
    console.error(
      'CDS: TextField contains an incorrect value for `maxLength`. Value needs to be greater than 0.'
    );
    return 0;
  }
  return maxLength;
};

export interface TextFieldProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'size'>,
    FormFieldProps {
  /**
   * If controlled, specify the value to display
   */
  value?: string;
  /**
   * If uncontrolled, specify an initial value
   */
  defaultValue?: string;
  /**
   * Specify an id to facilitate WAI-ARIA
   */
  id?: string;
  /**
   * Specify alignment of input text and helper text
   */
  textAlign?: TextFieldAlign;
  /**
   * If `true`, uses a `<textarea>` as the base element instead
   *
   * @default false
   */
  multiline?: boolean;
  /**
   * Specify the max character limit to allow
   */
  maxLength?: number;
  /**
   * If multiline is `true`, sets the number of columns (width) the text area should occupy
   */
  cols?: number;
  /**
   * If multiline is `true`, sets the number of row (height) the text area should occupy
   */
  rows?: number;
  /**
   * If controlled, specify a function to be called when the input value changes
   */
  onChange?: (e: React.ChangeEvent<TextFieldType>) => void;
}

export const TextField = React.forwardRef<TextFieldType, TextFieldProps>(
  (
    {
      value: valueProp,
      defaultValue = '',
      onChange,
      id: idProp,
      label,
      successText,
      errorText,
      helperText,
      placeholder,
      fullWidth,
      disabled,
      readOnly,
      className,
      textAlign = 'start',
      multiline,
      maxLength,
      margin = false,
      compact = false,
      required = false,
      hideRequiredStyle = false,
      rows,
      cols,
      style,
      id,
      'data-testid': dataTestId,
      ...props
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();

    const { status, descriptiveText } = useDescriptiveText(
      errorText,
      successText,
      helperText
    );

    const inputId = useId(idProp);
    const InputComponent = multiline ? 'textarea' : 'input';

    const validatedMaxLength = maxLength
      ? validateMaxLength(maxLength)
      : undefined;

    const [value, setValue] = useControlledState(
      defaultValue,
      valueProp,
      'TextField'
    );

    const handleChange = (e: React.ChangeEvent<TextFieldType>) => {
      // Uncontrolled, without value, store the value internally
      if (!valueProp) {
        setValue(e.target.value);
      }

      onChange?.(e);
    };

    return (
      <div
        id={id}
        data-testid={dataTestId}
        style={style}
        className={clsx([
          `${cssPrefix}-textfield-wrapper`,
          fullWidth && 'full-width',
          margin && 'textfield-margin',
          className,
        ])}
      >
        {label && (
          <InputLabel
            htmlFor={inputId}
            disabled={disabled}
            required={required}
            hideRequiredStyle={hideRequiredStyle}
          >
            {label}
          </InputLabel>
        )}
        <div className={`${cssPrefix}-input-helper-text-char-count`}>
          <InputComponent
            {...props}
            id={inputId}
            className={clsx([
              `${cssPrefix}-textfield`,
              status,
              fullWidth && 'full-width',
              compact && 'compact',
              `align-${textAlign}`,
            ])}
            disabled={disabled}
            readOnly={readOnly}
            rows={multiline ? rows : undefined}
            cols={multiline ? cols : undefined}
            value={value}
            onChange={handleChange}
            required={required}
            placeholder={placeholder}
            // TODO: Find a better way to forwardRef for dynamic element
            // @ts-ignore
            ref={ref}
            aria-describedby={`${inputId}-helper-text`}
            maxLength={validatedMaxLength}
          />
          <div
            className={clsx([
              `${cssPrefix}-helper-text-wrapper`,
              fullWidth ? 'full-width' : 'fit-content',
            ])}
          >
            {/* helper text */}
            <HelperText
              errorText={errorText}
              successText={successText}
              helperText={helperText}
              id={`${inputId}-helper-text`}
              style={{ width: '100%' }}
            />
            {/* char counter */}
            {validatedMaxLength && (
              <div className="input-char-counter">
                <HelperText
                  align={!descriptiveText ? 'left' : 'right'}
                  id={`${inputId}-character-count`}
                  helperText={`${value?.length} / ${validatedMaxLength}`}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);

import React, { createContext, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { Colors } from '../internal/types';
import { useCSSPrefix } from '../internal/hooks/useCSSPrefix';
import { Surface, SurfaceElevation } from '../Surface';
import { useMediaQuery } from '../useMediaQuery';
import { useBreakpoints } from '../useBreakpoints';
import './SideNavigation.scss';

export interface ISideNavigationWrapperContext {
  width: number;
}

export const SideNavigationWrapperContext =
  createContext<ISideNavigationWrapperContext | null>(null);

export type SideNavigationWrapperProps = React.ComponentPropsWithoutRef<'div'> &
  CommonProps;

export const SideNavigationWrapper: React.VFC<SideNavigationWrapperProps> =
  React.forwardRef<HTMLDivElement, SideNavigationWrapperProps>(
    ({ className, children, id: idProp, ...rest }, ref) => {
      // conditionally apply left margin to page content
      const states = {
        collapsed: 56,
        expanded: 152,
      };

      const [cssPrefix] = useCSSPrefix();
      const id = useId(idProp);
      const breakpoints = useBreakpoints();
      const isLargeAndUp = useMediaQuery(breakpoints.up('lg'));
      const { expanded, collapsed } = states;
      const width = isLargeAndUp ? expanded : collapsed;

      const value = useMemo(() => ({ width }), [width]);

      return (
        <SideNavigationWrapperContext.Provider value={value}>
          <div
            {...rest}
            ref={ref}
            id={id}
            className={clsx([
              `${cssPrefix}-side-navigation-wrapper`,
              className,
            ])}
          >
            {children}
          </div>
        </SideNavigationWrapperContext.Provider>
      );
    }
  );

export type PageContentElement = 'main' | 'section' | 'div';

export interface SideNavigationPageContentProps
  extends React.ComponentPropsWithoutRef<'main'> {
  /**
   * Optionally specify the root element of the SideNavigation page content from the following choices:
   * @default 'main'
   */
  as?: PageContentElement;
}

export const SideNavigationPageContent: React.VFC<SideNavigationPageContentProps> =
  React.forwardRef<HTMLDivElement, SideNavigationPageContentProps>(
    ({ as = 'main', className, children, id: idProp, ...rest }, ref) => {
      const [cssPrefix] = useCSSPrefix();
      const id = useId(idProp);
      const { width } = useContext(SideNavigationWrapperContext) || {};
      const Component: PageContentElement = as;

      return (
        <Component
          {...rest}
          ref={ref}
          id={id}
          style={{ marginLeft: width }}
          className={clsx([
            `${cssPrefix}-side-navigation-page-content`,
            className,
          ])}
        >
          {children}
        </Component>
      );
    }
  );

export type SideNavigationContextProps = Pick<SideNavigationProps, 'color'>;

export const SideNavigationContext =
  createContext<SideNavigationContextProps | null>(null);

export interface SideNavigationProps
  extends React.ComponentPropsWithoutRef<'nav'>,
    CommonProps {
  /**
   * Specify the color of the SideNavigation.
   * @default 'primary'
   */
  color?: Colors;
  /**
   * Specify the elevation of the SideNavigation.
   * @default 1
   */
  elevation?: SurfaceElevation;
  /**
   * Specify the content to render within the SideNavigation.
   */
  children: React.ReactNode;
}

export const SideNavigation = React.forwardRef<
  HTMLElement,
  SideNavigationProps
>(
  (
    {
      color = 'primary',
      elevation = 1,
      className,
      children,
      style,
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const id = useId(idProp);

    const { width } = useContext(SideNavigationWrapperContext) || {};

    const value = useMemo(() => ({ color }), [color]);

    return (
      <SideNavigationContext.Provider value={value}>
        <Surface
          {...rest}
          ref={ref}
          id={id}
          style={{ width, ...style }}
          className={clsx(
            `side-navigation`,
            `side-navigation-color-${color}`,
            'side-navigation-content-base',
            'side-navigation-content-grow',
            className
          )}
          as="nav"
          radii="none"
          elevation={elevation}
        >
          {children}
        </Surface>
      </SideNavigationContext.Provider>
    );
  }
);

interface ISideNavigationContent
  extends React.ComponentPropsWithoutRef<'div'>,
    CommonProps {
  /**
   * Specify the content to render inside of the SideNavigation content.
   */
  children: React.ReactNode;
  /**
   * Specify where to render the content within the SideNavigation.
   */
  section?: 'start' | 'middle' | 'end';
}

export const SideNavigationContent: React.VFC<ISideNavigationContent> =
  React.forwardRef<HTMLDivElement, ISideNavigationContent>(
    ({ children, section = 'start', className, id: idProp, ...props }, ref) => {
      const id = useId(idProp);
      const breakpoints = useBreakpoints();
      const isBelowLarge = useMediaQuery(breakpoints.down('lg'));

      return (
        <div
          {...props}
          ref={ref}
          id={id}
          className={clsx(className, {
            'side-navigation-content-start': section === 'start',
            'side-navigation-content-grow': section === 'middle',
            'side-navigation-content-end': section === 'end',
            'center-side-navigation-icons': isBelowLarge,
          })}
        >
          {children}
        </div>
      );
    }
  );

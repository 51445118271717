/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';

export default function useMediaQuery(query: string) {
  const supportMatchMedia =
    typeof window !== 'undefined' && typeof window.matchMedia !== 'undefined';

  if (!supportMatchMedia) return false;

  const [match, setMatch] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const queryList = matchMedia(query);

    const updateMatch = () => {
      setMatch(queryList.matches);
    };

    queryList.addListener(updateMatch);

    return () => {
      queryList.removeListener(updateMatch);
    };
  }, [query]);

  return match;
}

import React from 'react';
import { ModalScrollMode } from './Modal';

export interface ModalContextValue {
  /**
   * Can be called to close the modal.
   */
  onClose: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * This allows the ModalHeader component to tell the modal its id. Modals are generally labeled
   * by their header, so this makes the modal more accessible without the consumer having to do anything.
   */
  registerAriaLabeledBy: (id: string) => void;
  scrollMode?: ModalScrollMode;
}

// We'll use a context so that we don't have to pass stuff to the header.
// This allows us to have the close button auto-wired up, and for the modal
// to automatically have a "aria-labeled-by" that uses the header's id.
export const ModalContext = React.createContext<ModalContextValue>(
  {} as ModalContextValue
);

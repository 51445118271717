/**
 * Get the width of the vertical window scrollbar if it's visible
 * Copied from: https://github.com/react-restart/ui/blob/main/src/getScrollbarWidth.ts
 */
export function getBodyScrollbarWidth(ownerDocument = document) {
  const window = ownerDocument.defaultView!;

  return Math.abs(
    window.innerWidth - ownerDocument.documentElement.clientWidth
  );
}

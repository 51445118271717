import * as Icons from './icon-index';

export type IconType = keyof typeof IconMap;

export const IconMap = {
  'actions-horizontal': Icons.ActionsHorizontal,
  'actions-vertical': Icons.ActionsVertical,
  'address-book': Icons.AddressBook,
  'adjustments-horizontal': Icons.AdjustmentsHorizontal,
  alarm: Icons.Alarm,
  'alert-circle': Icons.AlertCircle,
  antenna: Icons.Antenna,
  archive: Icons.Archive,
  award: Icons.Award,
  bell: Icons.Bell,
  book: Icons.Book,
  'bookmark-filled': Icons.BookmarkFilled,
  'bookmark-outline': Icons.BookmarkOutline,
  briefcase: Icons.Briefcase,
  bulb: Icons.Bulb,
  calendar: Icons.Calendar,
  camera: Icons.Camera,
  celebrate: Icons.Celebrate,
  check: Icons.Check,
  'chevron-down': Icons.ChevronDown,
  'chevron-left': Icons.ChevronLeft,
  'chevron-right': Icons.ChevronRight,
  'chevron-up': Icons.ChevronUp,
  'circle-cross-filled': Icons.CircleCrossFilled,
  'circle-cross-outline': Icons.CircleCrossOutline,
  'circle-minus': Icons.CircleMinus,
  'circle-plus': Icons.CirclePlus,
  clock: Icons.Clock,
  close: Icons.Close,
  'cloud-download': Icons.CloudDownload,
  'cloud-upload': Icons.CloudUpload,
  cloud: Icons.Cloud,
  'coin-filled': Icons.CoinFilled,
  coin: Icons.Coin,
  copy: Icons.Copy,
  create: Icons.Create,
  'credit-card': Icons.CreditCard,
  dash: Icons.Dash,
  'device-laptop': Icons.DeviceLaptop,
  'discount-filled': Icons.DiscountFilled,
  discount: Icons.Discount,
  download: Icons.Download,
  edit: Icons.Edit,
  'error-filled': Icons.ErrorFilled,
  'error-outline': Icons.ErrorOutline,
  'external-link': Icons.ExternalLink,
  files: Icons.Files,
  filter: Icons.Filter,
  heart: Icons.Heart,
  hide: Icons.Hide,
  home: Icons.Home,
  'info-filled': Icons.InfoFilled,
  'info-outline': Icons.InfoOutline,
  'list-details': Icons.ListDetails,
  list: Icons.List,
  'lock-open': Icons.LockOpen,
  lock: Icons.Lock,
  logout: Icons.Logout,
  mail: Icons.Mail,
  menu: Icons.Menu,
  message: Icons.Message,
  microphone: Icons.Microphone,
  money: Icons.Money,
  movie: Icons.Movie,
  note: Icons.Note,
  notes: Icons.Notes,
  paperclip: Icons.Paperclip,
  pencil: Icons.Pencil,
  photo: Icons.Photo,
  'pin-filled': Icons.PinFilled,
  'pin-outline': Icons.PinOutline,
  play: Icons.Play,
  plus: Icons.Plus,
  print: Icons.Print,
  refresh: Icons.Refresh,
  'report-search': Icons.ReportSearch,
  search: Icons.Search,
  send: Icons.Send,
  settings: Icons.Settings,
  shapes: Icons.Shapes,
  'shopping-cart': Icons.ShoppingCart,
  show: Icons.Show,
  'sidebar-left': Icons.SidebarLeft,
  'sidebar-right': Icons.SidebarRight,
  sort: Icons.Sort,
  store: Icons.Store,
  'success-filled': Icons.SuccessFilled,
  'success-outline': Icons.SuccessOutline,
  trash: Icons.Trash,
  undo: Icons.Undo,
  user: Icons.User,
  'warning-filled': Icons.WarningFilled,
  'warning-outline': Icons.WarningOutline,
  wrench: Icons.Wrench,
  'zoom-in': Icons.ZoomIn,
  'zoom-out': Icons.ZoomOut,
};

type ICallback<T> = (message?: T) => void;
type IUnsubscribeFunc = () => void;

// A simple pub-sub class. Can optionally be instantiated with the type of message it will publish like:
//     this.pubsub = new PubSub<IMessage>();
export class PubSub<T> {
  private subscribers: Array<ICallback<T>> = [];

  public subscribe = (callback: ICallback<T>): IUnsubscribeFunc => {
    this.subscribers.push(callback);

    // Return an unsubscribe function
    return () => {
      const i = this.subscribers.indexOf(callback);
      if (i !== -1) {
        this.subscribers.splice(i, 1);
      }
    };
  };

  public publish = (message: T): void => {
    for (const subscriber of this.subscribers) {
      subscriber(message);
    }
  };
}

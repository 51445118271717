import React, {
  ComponentPropsWithoutRef,
  ReactNode,
  useContext,
  useMemo,
} from 'react';
import clsx from 'clsx';
import { useId } from '@reach/auto-id';
import { CommonProps } from '../internal/interfaces';
import { useCSSPrefix, useControlledState } from '../internal/hooks';
import {
  Collapse,
  CollapseTransitionProps,
} from '../internal/components/Collapse';
import {
  AccordionContext,
  AccordionContextType,
  AccordionItemKeyType,
  isAccordionItemOpen,
} from './AccordionContext';
import { Icon } from '../Icon';
import { Surface } from '../Surface';
import './Accordion.scss';

export interface AccordionProps
  extends Omit<ComponentPropsWithoutRef<'div'>, 'onChange'>,
    Omit<AccordionContextType, 'setActiveItemKey'>,
    CommonProps {
  /**
   * Specify at least one child element.
   */
  children: ReactNode;
}

export const Accordion = React.forwardRef<HTMLDivElement, AccordionProps>(
  (
    {
      activeItemKey: activeItemKeyProp,
      children,
      className,
      'data-testid': dataTestId,
      defaultActiveItemKey,
      elevation = 1,
      enableMultipleOpen = false,
      iconPosition = 'end',
      onChange,
      variant = 'default',
      id: idProp,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const [activeItemKey, setActiveItemKey] = useControlledState(
      defaultActiveItemKey,
      activeItemKeyProp,
      'Accordion'
    );

    const contextValue = useMemo(
      () => ({
        activeItemKey,
        defaultActiveItemKey,
        elevation,
        enableMultipleOpen,
        iconPosition,
        onChange,
        variant,
        setActiveItemKey,
      }),
      [
        activeItemKey,
        defaultActiveItemKey,
        elevation,
        enableMultipleOpen,
        iconPosition,
        onChange,
        variant,
        setActiveItemKey,
      ]
    );

    return (
      <AccordionContext.Provider value={contextValue}>
        <div
          {...rest}
          ref={ref}
          id={id}
          className={clsx([`${cssPrefix}-accordion`, className])}
          data-testid={dataTestId}
        >
          {children}
        </div>
      </AccordionContext.Provider>
    );
  }
);

export interface AccordionItemProps
  extends React.ComponentPropsWithoutRef<'div'>,
    CollapseTransitionProps,
    CommonProps {
  /**
   * The body for an individual accordion item that becomes visible when opened.
   */
  children?: ReactNode;
  /**
   * If `true`, prevent the accordion item from opening.
   * @default false
   */
  disabled?: boolean;
  /**
   * The header for an individual accordion item.
   */
  header?: ReactNode;
  /**
   * A unique key used to control expanding/collapsing this item's body.
   */
  itemKey: string;
}

export const AccordionItem = React.forwardRef<
  HTMLDivElement,
  AccordionItemProps
>(
  (
    {
      children,
      className,
      'data-testid': dataTestId,
      disabled,
      header,
      id: idProp,
      itemKey,
      onEnter,
      onEntering,
      onEntered,
      onExit,
      onExiting,
      onExited,
      unmountOnExit = true,
      addEndListener,
      ...rest
    },
    ref
  ) => {
    const [cssPrefix] = useCSSPrefix();
    const id = useId(idProp);

    const headerId = `${id}-accordion-item-header`;
    const bodyId = `${id}-accordion-item-body`;

    const {
      activeItemKey,
      elevation,
      enableMultipleOpen,
      iconPosition,
      onChange,
      variant,
      setActiveItemKey,
    } = useContext(AccordionContext) || {};

    const isSurfaceComponent = variant === 'surface';

    const Component = isSurfaceComponent ? Surface : 'div';

    const handleClick = () => {
      /**
       * Compare the item key in context with the given item key.
       * If they are the same, then collapse the component.
       */
      let itemKeyPassed: AccordionItemKeyType =
        itemKey === activeItemKey ? null : itemKey;

      if (enableMultipleOpen) {
        if (Array.isArray(activeItemKey)) {
          if (activeItemKey.includes(itemKey)) {
            itemKeyPassed = activeItemKey.filter((k) => k !== itemKey);
          } else {
            itemKeyPassed = [...activeItemKey, itemKey];
          }
        } else {
          // activeItemKey is undefined.
          itemKeyPassed = [itemKey];
        }
      }

      // Update our internal state if uncontrolled
      setActiveItemKey?.(itemKeyPassed);

      // Fire callback if controlled
      onChange?.(itemKeyPassed);
    };

    const isOpen = isAccordionItemOpen(itemKey, activeItemKey);

    return (
      <Component
        {...rest}
        ref={ref}
        id={id}
        data-testid={dataTestId}
        className={clsx([
          className,
          `${cssPrefix}-accordion-item`,
          isSurfaceComponent && `${cssPrefix}-accordion-item-surface`,
        ])}
        elevation={elevation}
      >
        <button
          type="button"
          id={headerId}
          className={clsx([
            `${cssPrefix}-accordion-item-header`,
            `${cssPrefix}-accordion-item-icon-${iconPosition}`,
          ])}
          aria-expanded={isOpen}
          aria-controls={bodyId}
          disabled={disabled}
          onClick={handleClick}
        >
          <div className={`${cssPrefix}-accordion-item-header-content`}>
            {header}
          </div>
          <Icon
            className={clsx(
              `${cssPrefix}-accordion-item-icon`,
              isOpen && 'rotate'
            )}
            icon="chevron-down"
            size="xs"
          />
        </button>
        <Collapse
          in={isOpen}
          innerWrapperProps={{
            'aria-labelledby': headerId,
            id: bodyId,
            className: `${cssPrefix}-accordion-item-body-content`,
            role: 'region',
          }}
          onEnter={onEnter}
          onEntering={onEntering}
          onEntered={onEntered}
          onExit={onExit}
          onExiting={onExiting}
          onExited={onExited}
          unmountOnExit={unmountOnExit}
          addEndListener={addEndListener}
        >
          {children}
        </Collapse>
      </Component>
    );
  }
);

import React, { ReactNode } from 'react';
import { ModalScrollMode } from '../Modal';
import { BreakpointConfigProps } from '../useBreakpoints';

export const ConfigContext = React.createContext<Config>({});

export interface Config {
  /**
   * Controls whether the modal body scrolls instead of the page when overflowing. However, if the scrollMode prop is passed directly to the modal, this will take precedence.
   */
  modalScrollMode?: ModalScrollMode;
  /**
   * Optionally, specify custom breakpoint values. Each breakpoint value indicates
   * the lower bound of the screen width up to but not including the next breakpoint value.
   *
   * @default {
   * xs: 0,
   * sm: 576,
   * md: 768,
   * lg: 992,
   * xl: 1200,
   * xxl: 1400,
   * }
   */
  breakpointConfig?: BreakpointConfigProps;
}

export interface ConfigProviderProps {
  value: Config;
  children?: ReactNode;
}

export function ConfigProvider({ value, children }: ConfigProviderProps) {
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}
